import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { SalesLayout } from '../layouts/sales-layout';
import { TrackingContent } from './tracking-content';
import styles from './style.css';

const TrackingCustomerPage: React.FC = () => {
    const t = usePhraseTranslater();
    const pageTitle = t('Tracking');
    const trackingComponent: JSX.Element = (<TrackingContent />);
    return (
        <SalesLayout
            pageKey="customer-tracking"
            pageTitle={pageTitle}
            pageContent={trackingComponent}
            salesLayoutClassName={styles.trackingPageLayout}
            showLeftSidebar={false}
            isGuestPage={false}
            loadCart
            loadCountries
        />
    );
};

export { TrackingCustomerPage as default };
